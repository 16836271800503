.header-red {
  color: white;
  background-color: #1B6E95 !important;
}

.summary,
.anomalies {
  margin-top: 20px;
  font-size: 14px;
}

.summary div,
.anomalies div {
  margin-bottom: 5px;
}

/* .hardik {
  height: 400px !important;
  width: 100% !important;
} */

.css-z8fhq1-MuiDataGrid-columnHeaders {

  background-color: #1B6E95 !important;
}


/* .graphresponsive {
  position: absolute !important;
  width: auto !important;
} */