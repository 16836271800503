.wrap-check-20 *,
.wrap-check-20 *::before,
.wrap-check-20 *::after {
  box-sizing: border-box;
}

.wrap-check-20 label {
  display: block;
  width: 20px; 
  height: 20px; 
  cursor: pointer;
}

.wrap-check-20 input {
  visibility: hidden;
  display: none;
}

.wrap-check-20 input:checked ~ .checkbox {
  transform: rotate(45deg);
  width: 10px; 
  margin-left: 8px; 
  border-color: #24c78e;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 0;
  border-width: 3px;
}

.wrap-check-20 .checkbox {
  display: block;
  width: inherit;
  height: inherit;
  border: 2px solid #434343; 
  border-radius: 6px;
  transition: all 0.375s;
}