.swal-popup {
    font-family: 'Arial', sans-serif; 
    font-size: 16px; 
}

.swal-confirm-button {
    display: flex;
    justify-content: flex-end;
    margin-left: 420px;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
    background-color: #fafafa !important;
  }

  .flip-card {
    background-color: transparent;
    width: 100%;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    background: linear-gradient(135deg, #18ADF0, #040404);
    color: #fff;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }
  