.form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .form-group {
    flex: 1;
    min-width: 200px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .progress-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .progress-bar span {
    flex: 1;
    text-align: center;
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-right: 5px;
  }
  
  .progress-bar .active {
    background-color: #007bff;
    color: white;
  }
  
  .form-section {
    margin-bottom: 20px;
  }
  
  .form-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .form-navigation button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form-navigation button:hover {
    background-color: #0056b3;
  }
  
  h1, h2, h3 {
    text-align: center;
    /* color: #333; */
  }
  
  h2 {
    margin-top: 40px;
  }

  .progress-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }
  
  .progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  .step-number {
    background-color: #ccc;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .step-title {
    font-size: 12px;
    text-align: center;
  }
  
  .progress-step.active .step-number {
    background-color: #f44336; /* Active step color */
  }
  
  .progress-indicator::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #ccc;
    z-index: 0;
  }
  
  .progress-step:first-child::before {
    content: none;
  }