.main {
    width: auto;
    display: block;
    overflow: hidden;
    margin-left: 40px;
    margin-right: 3px;
  }
  .paper {
    margin-top: 11px;
    width: 708px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    position: absolute;
    /* top: 15%;
       left: 25%; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
  }
  @media only screen and (max-width: 741px) {
    .paper {
      margin-top: 11px;
      width: 408px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5%;
      position: absolute;
      /* top: 15%;
           left: 25%; */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      justify-content: center;
    }
  }
  