.detailed-notifications-container {
    display: flex;
    /* padding: 16px; */
    background-color: #f9f9f9;
    margin-top: -42px;
    flex-direction: row;
    margin-top: -20px;
    height: 100%;
  }
  
  .sidebar {
    width: 350px;
    margin-top: -8px;
    background-color: #fff;
    /* border-right: 1px solid #e0e0e0; */
    /* padding: 16px; */
    /* margin-left: -16px; */
    transition: transform 0.3s ease-in-out;
  }
  
  .notifications-content {
    flex-grow: 1;
  }
  
  .notification-group {
    margin-bottom: 24px;
  }
  
  .notification-group-title {
    margin-bottom: 16px;
    color: #1b6e95;
  }
  
  .notification-card {
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
  }
  
  .notification-card:hover {
    background-color: #f0f0f0;
  }
  
  .notification-date {
    position: absolute;
    bottom: 8px;
    right: 16px;
    font-size: 0.8em;
    color: #888;
  }
  
  .notification-divider {
    margin-top: 16px;
  }
  
  .MuiListItemText-primary {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 40px); /* Adjust based on icon size */
  }