.custom-select {
    text-align: center;
}

.custom-select option {
    text-align: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 13px;
}

.switch input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.sliderhh {
    border-radius: 75px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
}

.sliderhh,
.sliderhh:before {
    border-radius: 75px;
    position: absolute;
}

.sliderhh:before {
    border-radius: 75px;
    background: #73AD21;
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    background-color: white;
}

input:checked+.sliderhh {
    background-color: #2196F3;
}

input:focus+.sliderhh {
    box-shadow: 0 0 1px #13c548;
}

/* input:checked+.sliderhh:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
} */

.custom-select .MuiSelect-select {
    width: 20px;
}