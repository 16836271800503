.App {
  text-align: center;
  width: "100%";
}

.displayedTime .count {
  padding: 2%;
  width: 100%;
  height: 100%;
  line-height: 87px;
  text-decoration: none !important;
  color: #3f51b5 !important;
  background-color: #5462b161 !important;
  border-radius: 10%;
}

.time {
  font-size: 32px;
}

.slider {
  /* left: rem; */
  position: relative;
  text-align: left;
  color: white !important;
}

.image {
  width: 160%;
  height: 55vh;
  border-radius: 10px;
  /* opacity: 0.7; */
}

/* .slider:hover .image {
  opacity: 1;
}

.slider:hover .top-left {
  color: black;
} */

.right-arrow {
  position: absolute;
  top: 45%;
  right: -45%;
  font-size: 3rem !important;
  color: #000;
  z-index: 1;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 45%;
  left: 32px;
  font-size: 3rem !important;
  color: #000;
  z-index: 1;
  cursor: pointer;
  user-select: none;
}

/* .top-left {
  position: absolute;
  position: absolute;
  text-align: center;
  color: white !important;
  top: 20px;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  top: 1%;
  left: 1%;
  width: 18%;
  font:bold;
  width: 59em;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  height: 79%;
  text-align: center;
  border-radius: 10px;
  box-shadow: gray 5px 2px 8px 3px;
  transition: transform 1s ease-out;
  transform: translateX(100%);
  transition: transform 1s ease-in;
  transform: translateX(0%);
} */
/* 
.top-left {
  position: absolute;
  top: 50%;
  left: 11.7%;
  width: 34.6%;
  z-index: 10;
  width: 59em;
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: white !important;
  height: 48%;
  text-align: center;
  border-radius: 10px;
  box-shadow: gray 5px 2px 8px 3px;
  transition: transform 1s ease-out;
  transform: translateX(100%);
  transition: transform 1s ease-in;
  transform: translateX(0%);
} */

.top-left {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0) !important;
  color: white !important;
  /* height: 90%;
  width: 100%; */
  top: 45%;
  /* left: 50%; */
  transform: translate(1%, -50%);
}

h2 span {
  color: white;
  font: bold 24px/45px Helvetica, Sans-Serif;
  letter-spacing: -1px;
  background: rgb(0, 0, 0);
  /* fallback color */
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
}

* {
  /* margin:0; */
  box-sizing: border-box;
}

.bday {
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
}

.container {
  display: flex;
  align-items: center;
  /* background-color: #fff; */
  border-radius: 10px;
  margin: 0px auto;
  padding: 20px;
  width: 200%;
}

.container .avtar {
  display: -webkit-inline-box;
  margin-left: 269px;
}

.container .avtar h3 {
  margin-top: 3px;
  font-size: 28px !important;
  font-size: 15px;
  margin-left: 15px;
  color: #428aa0;
  letter-spacing: 2px;
}

.root {
  height: 50% !important;
  background-image: url("https://img.freepik.com/free-photo/flat-lay-composition-wrapped-presents-with-copy-space_23-2148767640.jpg?t=st=1709787568~exp=1709791168~hmac=61fcab809a877601220bad5bb7b8f3881936449ba51fa1f5c213c18f49a39e69&w=1380");
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
  /* background-repeat: no-repeat; */
  background-position: center;
  /* padding: 50px 20px; */
}

@media (min-width: 992px) {
  .container {
    max-width: 800px;
  }
}

/*scrollbar css*/
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(27, 110, 149);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

#mui-rte-editor {
  text-align: left;
  padding: 15px;
}

#mui-rte-editor {
  width: 100%;
  overflow: scroll;
  word-break: break-all;
  border: 3px solid grey;
}

.test{
  display: -webkit-inline-box;
  margin-left: 300px;
  font-size: 28px !important;
  
}