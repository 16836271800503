.MuiDialog-paperScrollPaper {
    /* width="100"% */
    width: 100%;
}

.MuiDialog-paperWidthSm {
    max-width: 700px !important;
}

.MuiDialog-paperWidthMd {
    max-width: 693px !important;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 14px !important;
}