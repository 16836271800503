.birthday-card {
  position: relative;
}

.card {
  background-color: lightcyan;
  overflow: hidden;
  width: 745px;
  height: 464px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.2s;
}

/* .card:hover {
    transform: scale(1.1);
  } */

.card:before {
  content: "";
  /* / position: absolute; / */
  height: 5px;
  width: 250px;
  background-color: #fff;
  border-radius: 10px;
  top: 340px;
  left: 65px;
}

.cake {
  position: relative;
  top: 98px;
  right: 48px;
}

.card:after {
  content: "";
  /* / position: absolute; / */
  height: 5px;
  border-radius: 0 10px 10px 0;
  width: 122px;
  background-color: rgba(0, 0, 0, 0.1);
  top: 340px;
  left: 193px;
}

.cake-bottom {
  position: absolute;
  width: 200px;
  height: 60px;
  background-color: #06d6a0;
  top: 240px;
  left: 91px;
  border-radius: 7px 7px 0 0;
  overflow: hidden;
}

.cake-bottom:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 15px;
  left: -5px;
  display: hidden;
  border-radius: 0 0 50px 50px;
  background-color: white;
  box-shadow: 30px 0 white, 60px 0 white, 90px 0 white, 120px 0 white,
    150px 0 white, 180px 0 white;
}

.cake-bottom:before {
  content: "";
  position: absolute;
  background-color: #ef476f;
  width: 30px;
  height: 60px;
  left: 25px;
  box-shadow: 60px 0 #ef476f, 120px 0 #ef476f;
}

.cake-middle {
  position: absolute;
  width: 150px;
  height: 60px;
  background-color: #118ab2;
  border-radius: 7px 7px 0 0;
  top: 180px;
  left: 117px;
  box-shadow: inset 0 -20px #073b4c;
  overflow: hidden;
}

.cake-middle:before {
  content: "";
  position: absolute;
  background-color: #ef476f;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 20px;
  left: 10px;
  box-shadow: 15px 10px #ffd166, 30px 0 #06d6a0, 45px 10px #ef476f,
    60px 0 #ffd166, 75px 10px #06d6a0, 90px 0 #ef476f, 105px 10px #ffd166,
    120px 0 #06d6a0;
}

.cake-middle:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 15px;
  left: 0;
  border-radius: 0 0 50px 50px;
  background-color: white;
  box-shadow: 30px 0 white, 60px 0 white, 90px 0 white, 120px 0 white;
}

.cake-top {
  position: absolute;
  width: 100px;
  height: 60px;
  background-color: #ef476f;
  border-radius: 7px 7px 0 0;
  top: 120px;
  left: 143px;
  overflow: hidden;
  box-shadow: inset 0 20px #118ab2;
}

.cake-top:before {
  content: "";
  position: absolute;
  background-color: #118ab2;
  width: 15px;
  height: 35px;
  left: -5px;
  border-radius: 20px;
  box-shadow: 15px 10px #ef476f, 30px 5px #118ab2, 45px 5px #ef476f,
    60px -10px #118ab2, 75px 10px #ef476f, 90px 0 #118ab2;
}

.cake-top:after {
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  width: 50px;
  border-radius: 0 7px 0 0;
  height: 60px;
  left: 50px;
}

.candle {
  position: absolute;
  background: repeating-linear-gradient(
    -45deg,
    white,
    white 5px,
    #06d6a0 5px,
    #06d6a0 10px
  );
  width: 10px;
  height: 40px;
  border-radius: 3px 3px 0 0;
  top: 80px;
  left: 188px;
}

.candle:before {
  content: "";
  /* / position: absolute; / */
  background-color: #333;
  width: 1px;
  height: 10px;
  top: -10px;
  left: 4px;
}

.flame {
  position: absolute;
  opacity: 0.7;
}

.flame:before {
  content: "";
  position: absolute;
  background-color: #fb5607;
  border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
  transform: rotate(-45deg);
  width: 20px;
  height: 20px;
  top: 55px;
  left: 183px;
  animation: scale 0.5s linear infinite;
}

.flame:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #ffbe0b;
  width: 11px;
  height: 11px;
  top: 61px;
  left: 187.5px;
  animation: scale 0.5s linear infinite;
}

@keyframes scale {
  0% {
    transform: scaleY(1) rotate(-45deg);
  }
  25% {
    transform: scaleY(1.3) rotate(-45deg);
  }
  50% {
    transform: scaleY(1) rotate(-45deg);
  }
  75% {
    transform: scaleY(1.3) rotate(-45deg);
  }
  100% {
    transform: scaleY(1) rotate(-45deg);
  }
}

.shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  height: 60px;
  width: 74px;
  border-radius: 0 7px 0 0;
  top: 180px;
  left: 193px;
}

.shadow:before,
.shadow:after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
}

.shadow:before {
  height: 60px;
  width: 98.5px;
  border-radius: 0 7px 0 0;
  top: 60px;
}

.shadow:after {
  height: 40px;
  width: 5px;
  border-radius: 0 5px 0 0;
  top: -100px;
}

.confetti {
  position: absolute;
  width: 380px;
  height: 380px;
  overflow: hidden;
}

.squareOne,
.squareTwo,
.squareThree,
.squareFour,
.squareFive,
.squareSix,
.squareSeven,
.squareEight,
.squareNine,
.squareTen {
  position: absolute;
  width: 10px;
  height: 25px;
  top: -110px;
}

.squareOne {
  background-color: #ef476f;
  animation: down 2.5s linear infinite;
  left: 50px;
}

.squareTwo {
  background-color: #06d6a0;
  animation: down 2.3s linear infinite 0.2s;
  left: 150px;
}

.squareThree {
  background-color: #118ab2;
  animation: down 2.4s linear infinite 0.4s;
  left: 250px;
}

.squareFour {
  background-color: #ffbe0b;
  animation: down 2.7s linear infinite 0.1s;
  left: 300px;
}

.squareFive {
  background-color: #118ab2;
  animation: down 2.6s linear infinite 0.7s;
  left: 5px;
}

@keyframes down {
  0% {
    top: -110px;
    transform: rotate(0deg) rotateY(-90deg);
    opacity: 1;
  }
  100% {
    top: 400px;
    transform: rotate(360deg) rotateY(180deg);
    opacity: 0.5;
  }
}

@keyframes downTwo {
  0% {
    top: -130px;
    transform: rotate(0deg) rotateY(90deg);
    opacity: 1;
  }
  100% {
    top: 400px;
    transform: rotate(-360deg) rotateY(-180deg);
    opacity: 0.5;
  }
}

.squareSix {
  background-color: #ffbe0b;
  animation: downTwo 2.4s linear infinite 0.2s;
  left: 70px;
}

.squareSeven {
  background-color: #ef476f;
  animation: downTwo 2.1s linear infinite 0.7s;
  left: 170px;
}

.squareEight {
  background-color: #ef476f;
  animation: downTwo 2.4s linear infinite 0.9s;
  left: 280px;
}

.squareNine {
  background-color: #06d6a0;
  animation: downTwo 2.9s linear infinite 0.9s;
  left: 50px;
}

.squareTen {
  background-color: #118ab2;
  animation: downTwo 2.2s linear infinite 1.1s;
  left: 350px;
}

.text {
  position: absolute;
  font-family: tahoma;
  font-size: 35px;
  z-index: 1;
  font-weight: bold;
  width: 552px;
  text-align: center;
  color: #073b4c;
  top: 11%;
  margin-left: 41px;
}

.text-container {
  position: absolute;
  top: 168px;
  left: 273px;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
  float: right;
  font-family: 20px;
  font-size: x-large;
  font-style: italic;
}
.line {
  border-bottom: 2px solid;
}
.button {
  float: right;
  margin-right: 44px !important;
}
