.serachData {
    padding: 18.5px 14px !important;
  }
  
  .selectmonth {
    padding-bottom: 10px;
  }
  
  .header-red {
    color: white;
    background-color:#1B6E95 !important;
  }
   /* .MuiDataGrid-columnSeparator--sideRight */
   .MuiDataGrid-columnSeparator--sideRight {
    /* right: -12px; */
    display: none !important;
  }
  
  
  