.Ticketmodule .MuiTableBody-root .MuiTableRow-root {
    border: 2px solid #aba0a0;
}


.loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}


.wrap-check-20 *,
.wrap-check-20 *::before,
.wrap-check-20 *::after {
    box-sizing: border-box;
}

.wrap-check-20 label {
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.wrap-check-20 input {
    visibility: hidden;
    display: none;
}

.wrap-check-20 input:checked~.checkbox {
    transform: rotate(45deg);
    width: 10px;
    margin-left: 8px;
    border-color: #24c78e;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 0;
    border-width: 3px;
}

.wrap-check-20 .checkbox {
    display: block;
    width: inherit;
    height: inherit;
    border: 2px solid #434343;
    border-radius: 6px;
    transition: all 0.375s;
}